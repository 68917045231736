import React, { Component, useEffect } from 'react'
import pako from 'pako'
//BrowserRouter没有#   HashRouter 有#
import { BrowserRouter, Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom'
import { useState, createContext, useContext } from 'react'
import Login from '../pages/login'
import ScrollToTop from '../components/scrollToTop'
import { homeApi } from '../api/home-api'
import { huobiApi } from '../api/huobi'
import { onlineApi } from '../api/online-api'
import ChangeLine from '../pages/changeLine'
import ChangeLanguage from '../pages/changelanguage'
import ChatCenter from '../pages/chatcenter'
import Register from '../pages/register'
import HomeCenter from '../pages/homecenter'
import My from '../pages/my'
import Property from '../pages/property'
import NoiceInfo from '../pages/noiceInfo'
import RechargeList from '../pages/rechargelist'
import Recharge from '../pages/recharge'
import HelpList from '../pages/helplist'
import HelpInfo from '../pages/helpinfo'
import ExtractList from '../pages/extractlist'
import Extract from '../pages/extract'
import RechargeOrderList from '../pages/rechargeorderlist'
import LockUp from '../pages/lockUp'
import LockUpInfo from '../pages/lockUpInfo'
import LockUpOrder from '../pages/lockUpOrder'
import LockUpCenter from '../pages/lockUpCenter'
import QuotationCenter from '../pages/quotation'
import JieKuan from '../pages/jiekuan'
import Chat from '../pages/chat'
import SecurityCenter from '../pages/securityCenter'
import ChangePassword from '../pages/changePassword'
import Setting from '../pages/setting'
import C2Ctx from '../pages/c2ctx'
import C2CckImage from '../pages/c2cckImage'
import C2CckInfo from '../pages/c2cckinfo'
import C2CtkList from '../pages/c2ctkList'
import C2CckList from '../pages/c2cckList'
import ExtractOrderList from '../pages/extractorderlist'
import C2CckCenter from '../pages/c2cckcenter'
import Trade from '../pages/trade'
import Idcard from '../pages/idcard'
import IdCard from '../pages/idcard'
import JYJL from '../pages/jyjlCenter'
import JYJLLever from '../pages/jyjlLever'
import JYJLTrade from '../pages/jyjlTrade'
import MarketOrderInfo from '../pages/marketOrderInfo'
import Noice from '../pages/noice'
import MurmurChat from '../pages/murmurChat'
import Address from '../pages/addressAdd'
import AddressAdd from '../pages/addressAdd'
import AddressList from '../pages/addresslist'
import Lever from '../pages/lever'
import JYJLLever2 from '../pages/jyjlLever2'
import GJIdCard from '../pages/gjidcard'
import Downland from '../pages/download'
import DownlandInfo from '../pages/downloadInfo'
import ReloadPassword from '../pages/reloadPassword'
import JieKuanList from '../pages/jiekuanList'
import JieKuanInfo from '../pages/jiekuaninfo'
import Repayment from '../pages/repayment'
import RepaymentList from '../pages/repaymentList'
import RepaymentInfo from '../pages/repaymentInfo'
import PledgeBack from '../pages/pledgeBack'
import PledgeConfirm from '../pages/pledgeConfirm'
//全局
export const WSContext = createContext([] as any[])
export const WSMinContext = createContext([] as any[])
export const LoginContext = createContext([] as any[])
export const LoginMsgContext = createContext([] as any[])
export const NoLoginMsgContext = createContext([] as any[])

const AuthRoute = ({ children }: { children: React.ReactNode }) => {
  const [isReady, setIsReady] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    const uid = localStorage.getItem('uid')
    const userCode = localStorage.getItem('userCode')
    const companyId = localStorage.getItem('companyId')

    if (!uid || !userCode || !companyId) {
      navigate('/login', {
        replace: true,
        state: { from: location.pathname }
      })
    }
    setIsReady(true)
  }, [navigate, location])

  if (!isReady) {
    return <div>Loading...</div> // 或者你的loading组件
  }

  return <>{children}</>
}

export default function AppRouter() {
  const [login, setLogin] = useState(false)
  const [loginmsg, setloginmsg] = useState({} as any)
  const [nologinmsg, setnologinmsg] = useState({} as any)
  const [ctmarketlist, setCtmarketlist] = useState([] as any[])
  const reader = new FileReader()
  let WS1 = null
  let WS2 = null
  let timer: any
  const [coinListData, setCoinListData] = useState({} as any)
  const [coinListMinData, setCoinListMinData] = useState({} as any)
  let coinListDataMap = {} as any
  let coinListDataMinMap = {} as any
  const postKDataDay = {}
  const postKDataMin = {}
  const coinTypeMap = new Map<string, number>();
  // 定义一个延时函数
  function delay(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms))
  }
  //开始链接 wss1
  const startWS1 = () => {
    WS1 = new WebSocket('wss://api.huobi.pro/ws')
    WS1.onopen = async () => {
      console.info('链接打开WS1')
      for (const key in postKDataDay) {
        WS1.send(JSON.stringify(postKDataDay[key])) //实时
        await delay(200) // 等待1秒钟
      }
    }
    WS1.onclose = () => {
      console.info('链接关闭')
    }
    WS1.onmessage = async (event) => {
      reader.onload = (e) => {
        let ploydata = new Uint8Array(e.target?.result as ArrayBufferLike)
        let msg = pako.inflate(ploydata, { to: 'string' })
        handleData(msg, WS1)
      }
      try {
        reader.readAsArrayBuffer(event.data)
      } catch (e) {}
    }
    WS1.onerror = () => {
      console.info('链接失败')
    }
  }

  //开始链接 wss2
  const startWS2 = () => {
    WS2 = new WebSocket('wss://api.huobi.pro/ws')
    WS2.onopen = async () => {
      console.info('链接打开WS2')
      for (const key in postKDataMin) {
        WS2.send(JSON.stringify(postKDataMin[key])) //实时
        await delay(200) // 等待1秒钟
      }
    }
    WS2.onclose = () => {
      console.info('链接关闭')
    }
    WS2.onmessage = async (event) => {
      reader.onload = (e) => {
        let ploydata = new Uint8Array(e.target?.result as ArrayBufferLike)
        let msg = pako.inflate(ploydata, { to: 'string' })
        handleData(msg, WS2)
      }
      try {
        reader.readAsArrayBuffer(event.data)
      } catch (e) {}
    }
    WS2.onerror = () => {
      console.info('链接失败')
    }
  }

  // 发送响应信息
  const sendHeartMessage = (ping, WS) => {
    WS?.send(JSON.stringify({ pong: ping }))
  }

  //关闭WSS1
  const closeWS1 = () => {
    WS1?.close()
  }

  //关闭WSS2
  const closeWS2 = () => {
    WS2?.close()
  }

  //处理响应数据
  const handleData = (msg: string, WS) => {
    let data = JSON.parse(msg)
    if (data.ping) {
      // 如果是 ping 消息
      sendHeartMessage(data.ping, WS)
    } else if (data.status === 'ok') {
      if(data && data.data) {
        for (let key in postKDataDay) {
          
          key = key.substring(0, key.length - 1)
          if (data.rep === `market.${key}usdt.kline.1min`) {
            coinListDataMap = { ...coinListDataMap, [key]: data.data[0] }
            
            setCoinListData(coinListDataMap)

            setTimeout(() => {
              const currentTime = new Date().getTime()
              const adjustedTime = currentTime - 24 * 60 * 60 * 1000
              const postKDataDay = {
                id: "id" + currentTime,
                req: `market.${key}usdt.kline.1min`,
                from: adjustedTime/1000,
                to: adjustedTime/1000 + 60,
              }
              WS1.send(JSON.stringify(postKDataDay))
            }, 10000);
          }
        }
        for (let key in postKDataMin) {
          key = key.substring(0, key.length - 1)
          if (data.rep === `market.${key}usdt.kline.1min`) {
            coinListDataMinMap = { ...coinListDataMinMap, [key]: data.data[0] }
            
            setCoinListMinData(coinListDataMinMap)
          }
        }
      }
    } else {
      //eos/bch/eth
      const datat = data.tick
      for (let key in postKDataDay) {
        key = key.substring(0, key.length - 1)
        if (data.ch === `market.${key}usdt.kline.1day`) {
          coinListDataMap = { ...coinListDataMap, [key]: datat }  
          setCoinListData(coinListDataMap)
        }
      }
      for (let key in postKDataMin) {
        key = key.substring(0, key.length - 1)
        if (data.ch === `market.${key}usdt.kline.1min`) {
          coinListDataMinMap = { ...coinListDataMinMap, [key]: datat }
          setCoinListMinData(coinListDataMinMap)
        }
      }
    }
  }
 

  const getHistoryKLineData = async () => {
      let type = 1 // 默认值为1
      
      const dataML = await homeApi.ctmarketlist({ pageNum: 1, pageSize: 100 })
      if (dataML.ok) {
        const list = dataML.data.records
        list.sort((d, e) => d.sort - e.sort)

        if (Array.isArray(list) && list.length !== 0) {
          const matchedItem = list.find((item) => item.type === 2)
          if (matchedItem) {
            const baseUrl = 'https://api.huobi.pro'
            const huobiPeriod = '1min'
            const data = await huobiApi.getHistoryK(matchedItem.symbol, huobiPeriod, 1441)
            type = matchedItem.type
            coinListData[matchedItem.issueName] = {
              id: data.data[0].id,
              open: data.data[0].open,
              close: data.data[0].close,
              high: data.data[0].high,
              low: data.data[0].low,
              vol: data.data[0].vol
            }
            setCoinListData(coinListData)
          }
        }
    }
  }

  const loadctmarketlistData = async () => {
    const data = await homeApi.ctmarketlist({ pageNum: 1, pageSize: 100 })
    if (data.ok) {
      setCtmarketlist(data.data.records)
      const list = data.data.records
      list.sort((d, e) => d.sort - e.sort)
      let postKDataDayTemp = {}
      let postKDataMinTemp = {}
      for (const ctmarket of list) {
        if (ctmarket.status == 1) {
          coinTypeMap.set(ctmarket.coinname, ctmarket.type);
          coinListDataMap[ctmarket.coinname] = {}
          if(ctmarket.type === 1) {
            postKDataDay[ctmarket.coinname + 'K'] = {
              name: ctmarket.coinname,
              sub: `market.${ctmarket.coinname}usdt.kline.1day`
            }
            postKDataMin[ctmarket.coinname + 'K'] = {
              name: ctmarket.coinname,
              sub: `market.${ctmarket.coinname}usdt.kline.1min`
            }
          }else {
            const currentTime = new Date().getTime()
            const adjustedTime = currentTime - 24 * 60 * 60 * 1000
            postKDataDay[ctmarket.coinname + 'K'] = {
              id: "id" + currentTime,
              req: `market.${ctmarket.coinname}usdt.kline.1min`,
              from: adjustedTime/1000,
              to: adjustedTime/1000 + 60,
            }
            postKDataMin[ctmarket.coinname + 'K'] = {
              id: "id" + currentTime,
              req: `market.${ctmarket.coinname}usdt.kline.1min`,
              from: adjustedTime/1000,
              to: adjustedTime/1000 + 60,
            }
          }
        }
      }
      setCoinListData(coinListDataMap)
    }
  }

  const loaduserMsgData = async () => {
    let uid = localStorage.getItem('uid')
    uid = uid ? uid : 0
    try {
      const data = await onlineApi.userMsg({ uid })
      if (data.ok) {
        setloginmsg(data.data?.data)
      }
    } catch (error) {
      console.log('faith=============error', error)
    }
  }
  const loaduserUuidMsgData = async () => {
    let uuid = localStorage.getItem('murmur')
    uuid = uuid ? uuid : 0
    try {
      const data = await onlineApi.userUuidMsg({ uuid })
      if (data.ok) {
        setnologinmsg(data.data?.data)
      }
    } catch (error) {
      console.log('faith=============error-2', error)
    }
  }
  const getElement = (element) => {
    //关键信息
    const uid = localStorage.getItem('uid')
    const userCode = localStorage.getItem('userCode')
    const companyId = localStorage.getItem('companyId')
    if (uid && userCode && companyId) {
      return element
    } else {
      return <Navigate to='/login' />
    }
  }

  useEffect(() => {
    
    const timer = setInterval(() => {
      loaduserMsgData()
      loaduserUuidMsgData()
    }, 5000)

    return () => {
      clearInterval(timer)
    }
  }, [])
  useEffect(() => {
    loadctmarketlistData().then(() => {
      startWS1()
      startWS2()
    })
    return () => {
      closeWS1()
      closeWS2()
    }
  }, [])
  useEffect(() => {
    const uid = localStorage.getItem('uid')
    if (uid) {
      setLogin(true)
    }
  }, [])
  return (
    <WSContext.Provider value={[coinListData, setCoinListData]}>
      <WSMinContext.Provider value={[coinListMinData, setCoinListMinData]}>
        <LoginContext.Provider value={[login, setLogin]}>
          <LoginMsgContext.Provider value={[loginmsg, setloginmsg]}>
            <NoLoginMsgContext.Provider value={[nologinmsg, setnologinmsg]}>
              <BrowserRouter>
                <ScrollToTop>
                  <Routes>
                    <Route path="/login" element={<Login />}></Route>
                    {/* <Route path="/register" element={<Register />}></Route> */}
                    <Route
                      path="/register/:inviteCode?"
                      element={<Register />}
                    />
                    <Route path="/homecenter" element={<HomeCenter />}></Route>
                    <Route path="/pledgeBack" element={<PledgeBack />}></Route>
                    <Route
                      path="/pledgeConfirm/:id"
                      element={<PledgeConfirm />}
                    ></Route>
                    <Route
                      path="/mycenter"
                      element={
                        <AuthRoute>
                          <My />
                        </AuthRoute>
                      }
                    ></Route>
                    <Route
                      path="/reloadPassword"
                      element={<ReloadPassword />}
                    ></Route>
                    <Route path="/changeline" element={<ChangeLine />}></Route>
                    <Route
                      path="/propertycenter"
                      element={
                        <AuthRoute>
                          <Property />
                        </AuthRoute>
                      }
                    ></Route>
                    <Route
                      path="/changelanguage"
                      element={<ChangeLanguage />}
                    ></Route>
                    <Route path="/chatcenter" element={<ChatCenter />}></Route>
                    <Route
                      path="/chat"
                      element={
                        <AuthRoute>
                          <Chat />
                        </AuthRoute>
                      }
                    ></Route>
                    <Route path="/murmurchat" element={<MurmurChat />}></Route>
                    <Route
                      path="/securitycenter"
                      element={
                        <AuthRoute>
                          <SecurityCenter />
                        </AuthRoute>
                      }
                    ></Route>
                    <Route
                      path="/changePassword"
                      element={
                        <AuthRoute>
                          <ChangePassword />
                        </AuthRoute>
                      }
                    ></Route>
                    <Route
                      path="/noiceInfo"
                      element={
                        <AuthRoute>
                          <NoiceInfo />
                        </AuthRoute>
                      }
                    ></Route>
                    <Route
                      path="/extractlist"
                      element={
                        <AuthRoute>
                          <ExtractList />
                        </AuthRoute>
                      }
                    ></Route>
                    <Route
                      path="/extractorderlist"
                      element={
                        <AuthRoute>
                          <ExtractOrderList />
                        </AuthRoute>
                      }
                    ></Route>
                    <Route
                      path="/extract/:id"
                      element={
                        <AuthRoute>
                          <Extract />
                        </AuthRoute>
                      }
                    ></Route>
                    <Route
                      path="/rechargelist"
                      element={
                        <AuthRoute>
                          <RechargeList />
                        </AuthRoute>
                      }
                    ></Route>
                    <Route
                      path="/rechargeorderlist"
                      element={
                        <AuthRoute>
                          <RechargeOrderList />
                        </AuthRoute>
                      }
                    ></Route>
                    <Route
                      path="/setting"
                      element={
                        <AuthRoute>
                          <Setting />
                        </AuthRoute>
                      }
                    ></Route>
                    <Route
                      path="/recharge/:id"
                      element={
                        <AuthRoute>
                          <Recharge />
                        </AuthRoute>
                      }
                    ></Route>
                    <Route
                      path="/helplist"
                      element={
                        <AuthRoute>
                          <HelpList />
                        </AuthRoute>
                      }
                    ></Route>
                    <Route
                      path="/helpinfo/:id"
                      element={
                        <AuthRoute>
                          <HelpInfo />
                        </AuthRoute>
                      }
                    ></Route>
                    <Route
                      path="/lockUpCenter"
                      element={
                        <AuthRoute>
                          <LockUpCenter />
                        </AuthRoute>
                      }
                    ></Route>
                    <Route
                      path="/lockUp"
                      element={
                        <AuthRoute>
                          <LockUp />
                        </AuthRoute>
                      }
                    ></Route>
                    <Route
                      path="/lockUpInfo/:id"
                      element={
                        <AuthRoute>
                          <LockUpInfo />
                        </AuthRoute>
                      }
                    ></Route>
                    <Route
                      path="/lockUpOrder"
                      element={
                        <AuthRoute>
                          <LockUpOrder />
                        </AuthRoute>
                      }
                    ></Route>
                    <Route
                      path="/quotation"
                      element={
                        <AuthRoute>
                          <QuotationCenter />
                        </AuthRoute>
                      }
                    ></Route>
                    <Route
                      path="/trade/:name"
                      element={
                        <AuthRoute>
                          <Trade />
                        </AuthRoute>
                      }
                    />
                    {/* <Route path='/trade/:name' element={<AuthRoute>(<Trade />)</AuthRoute>}></Route> */}
                    <Route
                      path="/borrowmoney"
                      element={
                        <AuthRoute>
                          <JieKuan />
                        </AuthRoute>
                      }
                    ></Route>
                    <Route
                      path="/jiekuanlist"
                      element={
                        <AuthRoute>
                          <JieKuanList />
                        </AuthRoute>
                      }
                    ></Route>
                    <Route
                      path="/jiekuaninfo/:orderNo"
                      element={
                        <AuthRoute>
                          <JieKuanInfo />
                        </AuthRoute>
                      }
                    ></Route>
                    <Route
                      path="/c2cckcenter"
                      element={
                        <AuthRoute>
                          <C2CckCenter />
                        </AuthRoute>
                      }
                    ></Route>
                    <Route
                      path="/c2cckList"
                      element={
                        <AuthRoute>
                          <C2CckList />
                        </AuthRoute>
                      }
                    ></Route>
                    <Route
                      path="/c2ctkList"
                      element={
                        <AuthRoute>
                          <C2CtkList />
                        </AuthRoute>
                      }
                    ></Route>
                    <Route
                      path="/c2cckinfo/:orderNo"
                      element={
                        <AuthRoute>
                          <C2CckInfo />
                        </AuthRoute>
                      }
                    ></Route>
                    <Route
                      path="/c2cckimage/:orderNo"
                      element={
                        <AuthRoute>
                          <C2CckImage />
                        </AuthRoute>
                      }
                    ></Route>
                    <Route
                      path="/c2ctx/:currencyId"
                      element={
                        <AuthRoute>
                          <C2Ctx />
                        </AuthRoute>
                      }
                    ></Route>
                    <Route
                      path="/idcard"
                      element={
                        <AuthRoute>
                          <IdCard />
                        </AuthRoute>
                      }
                    ></Route>
                    <Route
                      path="/gjidcard"
                      element={
                        <AuthRoute>
                          <GJIdCard />
                        </AuthRoute>
                      }
                    ></Route>
                    <Route
                      path="/jyjl"
                      element={
                        <AuthRoute>
                          <JYJL />
                        </AuthRoute>
                      }
                    ></Route>
                    <Route path="/download" element={<Downland />}></Route>
                    <Route
                      path="/downloadInfo"
                      element={<DownlandInfo />}
                    ></Route>
                    <Route
                      path="/jyjlLever"
                      element={
                        <AuthRoute>
                          <JYJLLever />
                        </AuthRoute>
                      }
                    ></Route>
                    <Route
                      path="/jyjlLever2"
                      element={
                        <AuthRoute>
                          <JYJLLever2 />
                        </AuthRoute>
                      }
                    ></Route>
                    <Route
                      path="/jyjlTrade"
                      element={
                        <AuthRoute>
                          <JYJLTrade />
                        </AuthRoute>
                      }
                    ></Route>
                    <Route
                      path="/marketOrderInfo/:id"
                      element={
                        <AuthRoute>
                          <MarketOrderInfo />
                        </AuthRoute>
                      }
                    ></Route>
                    <Route
                      path="/noice"
                      element={
                        <AuthRoute>
                          <Noice />
                        </AuthRoute>
                      }
                    ></Route>
                    <Route
                      path="/addressadd"
                      element={
                        <AuthRoute>
                          <AddressAdd />
                        </AuthRoute>
                      }
                    ></Route>
                    <Route
                      path="/addresslist"
                      element={
                        <AuthRoute>
                          <AddressList />
                        </AuthRoute>
                      }
                    ></Route>
                    <Route
                      path="/lever/:name"
                      element={
                        <AuthRoute>
                          <Lever />
                        </AuthRoute>
                      }
                    ></Route>
                    <Route
                      path="/repayment/:id/:num"
                      element={
                        <AuthRoute>
                          <Repayment />
                        </AuthRoute>
                      }
                    ></Route>
                    <Route
                      path="/repaymentList"
                      element={
                        <AuthRoute>
                          <RepaymentList />
                        </AuthRoute>
                      }
                    ></Route>
                    <Route
                      path="/repaymentInfo/:id"
                      element={
                        <AuthRoute>
                          <RepaymentInfo />
                        </AuthRoute>
                      }
                    ></Route>
                    {/* 404 */}
                    <Route
                      path="*"
                      element={<Navigate to="/homecenter" />}
                    ></Route>
                  </Routes>
                </ScrollToTop>
              </BrowserRouter>
            </NoLoginMsgContext.Provider>
          </LoginMsgContext.Provider>
        </LoginContext.Provider>
      </WSMinContext.Provider>
    </WSContext.Provider>
  );
}
