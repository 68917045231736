import React, { useEffect, useState, useRef, useContext } from 'react'
import { init, KLineData, dispose } from 'klinecharts'
import pako from 'pako'
import './index.css'
import { WSContext, WSMinContext } from '../../../../router/router'
import { getText } from '../../../../utils/util'
import { useTranslation } from 'react-i18next'
import { huobiApi } from '../../../../api/huobi'
import { homeApi } from '../../../..//api/home-api'
import { set } from '@ant-design/plots/es/core/utils'
import { time } from 'console'
export default function MyChartComponent({ nowTab, setIndex, setType, timeindex, setSelfType, settimeindex, ctmarketlist }) {
  // console.log('faith=============setTypesetTypesetType', timeindex)
  const [coinListData, setCoinListData] = useContext(WSContext)
  const [coinListMinData, _] = useContext(WSMinContext)
  const { t: translate } = useTranslation()
  const [kLineDatas, setkLineDatas] = useState([] as any[])
  const [kchart, setkchart] = useState()
  const [status, setStatus] = useState<number | undefined>(undefined)

  const reader = new FileReader()
  let WS1 = null


  const klinePeriod = [
    { multiplier: 1, timespan: 'minute' },
    { multiplier: 1, timespan: 'minute' },
    { multiplier: 5, timespan: 'minute' },
    { multiplier: 15, timespan: 'minute' },
    { multiplier: 30, timespan: 'minute' },
    { multiplier: 1, timespan: 'hour' },
    { multiplier: 1, timespan: 'day' },
    { multiplier: 1, timespan: 'week' }
  ]

  // console.log('faith=============ctmarketlist', ctmarketlist)

  const adjustTimestamp = (timestamp) => {
    const currentTime = new Date().getTime()
    const adjustedTime = timestamp * 1000 + 24 * 60 * 60 * 1000
    return adjustedTime <= currentTime ? timestamp * 1000 : null
  }

  //获取历史
  const getHistoryKLineData = async (symbol, period) => {
    const baseUrl = 'https://api.huobi.pro'
    const huobiPeriod = mapPeriodToHuobi(period)
    const data = await huobiApi.getHistoryK(symbol.ticker.toLowerCase(), huobiPeriod, 2000)

    let type = 1 // 默认值为1
    const currentSymbol = symbol.ticker.toLowerCase()
    
    const dataML = await homeApi.ctmarketlist({ pageNum: 1, pageSize: 100 })
    if (dataML.ok) {
      const list = dataML.data.records
      list.sort((d, e) => d.sort - e.sort)
      ctmarketlist = list
    }

    // 遍历ctmarketlist查找匹配的symbol
    if (Array.isArray(ctmarketlist) && ctmarketlist.length !== 0) {
      const matchedItem = ctmarketlist.find((item) => item.symbol === currentSymbol)
      if (matchedItem) {
        type = matchedItem.type
      }
    }
    let kLineData

 
    if (type == 2) {
      // 如果type为2，使用新逻辑（带时间调整和过滤的逻辑）
      kLineData = data.data
        .map((item) => {
          const adjustedTime = adjustTimestamp(item.id)
          if (adjustedTime === null) return null
          return {
            timestamp: adjustedTime,
            open: item.open,
            close: item.close,
            high: item.high,
            low: item.low,
            volume: item.vol
          }
        })
        .filter((item) => item !== null)
        setSelfType(2)
        setStatus(2)
    } else {
      // 如果type不为2，使用原来的逻辑
      kLineData = data.data.map((item) => ({
        timestamp: item.id * 1000,
        open: item.open,
        close: item.close,
        high: item.high,
        low: item.low,
        volume: item.vol
      }))
      setSelfType(1)
      setStatus(1)
    }
    kLineData = kLineData.reverse()
    setkLineDatas(kLineData)
    return kLineData
  }

  // 将自定义周期映射到火币支持的周期
  const mapPeriodToHuobi = (period) => {
    // 假设我们基于 timespan 属性来进行映射
    switch (period.timespan) {
      case 'minute':
        // 假设 multiplier 为 1 时映射为 '1min', 为 5 时映射为 '5min'
        return `${period.multiplier}min`
      case 'hour':
        // 假设 multiplier 为 1 时映射为 '60min'（火币没有小时表示，所以用分钟表示）
        return `${period.multiplier * 60}min`
      case 'day':
        // 假设 multiplier 为 1 时映射为 '1day'
        return `${period.multiplier}day`
      case 'week':
        return `${period.multiplier}week`
      // 添加更多映射规则...
      default:
        return '1min' // 默认值
    }
  }

  //初始化图标
  const initChart = async () => {
    if (kchart) {
      dispose('k-line-chart')
    }
    const chart = init('k-line-chart')
    setkchart(chart)
    chart.setStyles({
      grid: {
        horizontal: {
          size: 0.1,
          style: 'solid'
        },
        vertical: {
          size: 0.1,
          style: 'solid'
        }
      },
      candle: {
        type: timeindex == 1 ? 'area' : 'candle_solid',
        area: {
          lineSize: 1
        },
        point: {
          show: false
        },
        tooltip: {
          text: {
            size: 10
          },
          //清空头部
          custom: []
        }
      },
      indicator: {
        tooltip: {
          // showType: "rect",
          text: {
            size: 9
          }
        }
      },
      yAxis: {
        // position: "left",
        axisLine: {
          size: 1,
          color: '#3E444D'
        }
      },
      xAxis: {
        axisLine: {
          size: 1,
          color: '#3E444D'
        }
      },
      separator: {
        size: 1,
        color: '#3E444D'
      }
    })
    chart.setOffsetRightDistance(0)
    chart.setTimezone('Europe/Berlin')
    chart.createIndicator('MA', true, { id: 'candle_pane' })
    chart.createIndicator('VOL')
    chart.createIndicator('MACD')
    const data = await getHistoryKLineData(
      {
        ticker: `${nowTab.toUpperCase()}USDT`,
        name: `${nowTab.toUpperCase()}USDT`
      },
      klinePeriod[timeindex - 1]
    )
    
  const prices = data.map((d) => [d.high, d.low]).flat();
  const minPrice = Math.min(...prices);
  const maxPrice = Math.max(...prices);
  const priceRange = maxPrice - minPrice;
  const padding = priceRange * 0.2; // 添加 20% 的上下缓冲

  // 设置 Y 轴范围
  chart.setPriceVolumePrecision(4, 2); // 设置价格精度为 4 位小数
    chart.applyNewData(data)
  }

  function delay(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms))
  }

    const startWS1 = () => {
      WS1 = new WebSocket('wss://api.huobi.pro/ws')
      WS1.onopen = async () => {
        console.info('链接打开WS1')
        //for (const key in postKDataDay) {
          
        const currentTime = new Date().getTime()
          const adjustedTime = currentTime - 24 * 60 * 60 * 1000
          const postKDataDay = {
            id: "id" + currentTime,
            req: `market.${nowTab}usdt.kline.1min`,
            from: adjustedTime/1000,
            to: adjustedTime/1000 + 60,
          }
          WS1.send(JSON.stringify(postKDataDay)) //实时
        //}
      }
      WS1.onclose = () => {
        console.info('链接关闭')
      }
      WS1.onmessage = async (event) => {
        reader.onload = (e) => {
          let ploydata = new Uint8Array(e.target?.result as ArrayBufferLike)
          let msg = pako.inflate(ploydata, { to: 'string' })
          handleData(msg, WS1)
        }
        try {
          reader.readAsArrayBuffer(event.data)
        } catch (e) {}
      }
      WS1.onerror = () => {
        console.info('链接失败')
      }
    }

    const handleData = (msg: string, WS) => {
      let data = JSON.parse(msg)
      
      if (data.ping) {
        WS?.send(JSON.stringify({ pong: data.ping }))
      } else if (data.status === 'ok') {
        if(data && data.data) {
          kchart.updateData({
            timestamp: data.data[0].id * 1000,
            open: data.data[0].open,
            close: data.data[0].close,
            high: data.data[0].high,
            low: data.data[0].low,
            volume: data.data[0].vol
          })
          // coinListData[nowTab] = {
          //   id: data.data[0].id,
          //   open: data.data[0].open,
          //   close: data.data[0].close,
          //   high: data.data[0].high,
          //   low: data.data[0].low,
          //   vol: data.data[0].vol
          // }
          // setCoinListData(coinListData)
          
          setTimeout(() => {
            const currentTime = new Date().getTime()
            const adjustedTime = currentTime - 24 * 60 * 60 * 1000
            const postKDataDay = {
              id: "id" + currentTime,
              req: `market.${nowTab}usdt.kline.1min`,
              from: adjustedTime/1000,
              to: adjustedTime/1000 + 60,
            }
            WS1.send(JSON.stringify(postKDataDay))
          }, 1000);
        }
      } else {
        //eos/bch/eth
        const datat = data.tick
        
  
      }
    }
    useEffect(() => {
      if(kchart) {
      startWS1()
    }
    } , [kchart])

  //监控变化
  useEffect(() => {
    const item = coinListData[nowTab]
    const item1 = coinListMinData[nowTab]
    if (kchart && item && item1 && status!==2) {
      kchart.updateData({
        timestamp: item1.id * 1000,
        open: item1.open,
        close: item.close,
        high: item1.high,
        low: item1.low,
        volume: item1.vol
      })
    }
  }, [coinListMinData[nowTab]?.close, coinListData[nowTab]?.close])

  useEffect(() => {
    initChart()
  }, [timeindex, nowTab])
  return (
    <div>
      <div id='k-line-chart' style={{ height: '580px', width: '100%' }}></div>
      <div
        style={{
          height: '70px'
        }}></div>
      {/* 底部按钮 */}
      <div className='btn-footer1-1'>
        <div className='btn-footer1-2'>
          <button
            className='btn-footer1-3'
            onClick={() => {
              setIndex(3)
              setType(1)
            }}>
            {translate(getText('看漲1'))}
          </button>
          <button
            className='btn-footer1-4'
            onClick={() => {
              setIndex(3)
              setType(2)
            }}>
            {translate(getText('看跌'))}
          </button>
        </div>
      </div>
    </div>
  )
}
